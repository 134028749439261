.content {
  position: relative;
  height: 45vw;
  margin-top: -40px;

  &__background,
  &__background__shadow,
  &__background__image,
  &__area {
    position: absolute;
    top: 0;
    bottom: 0;
  }

  &__background {
    left: 0;
    right: 0;
    top:5px;

    &__shadow {
      left: 0;
      background: #000;
      width: 40%;
      z-index: 2;

      &:before {
        content: '';
        position: absolute;
        z-index: 10;
        background-image: linear-gradient(to right,#000,transparent);
        top: 0;
        bottom: 0;
        left: 100%;
        width: 275px;
      }
      @media only screen and (max-width: 768px) {
        background: auto;
        width: auto;
      }
    }

    &__image {
      right: 0;
      width: 70%;
      height: 100%;
      background-position: center 10%;
      background-size: cover;
      z-index: 1;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &__area {
    left: 0;
    right: 0;
    height: 100%;
    z-index: 3;

    &__container {
      padding: 30px 70px;
      color: wheat;

      @media only screen and (max-width: 415px) {
        padding: 17px 2px;
      }
      @media (min-width: 416px) and (max-width: 584px) {
        padding: 25px 30px;
      }
    }
    // @media only screen and (max-width: 415px) {
    //   margin-top: 195px;
    // }
  }

  &__title {
    font-size: 22px;
    color: #fff;
    font-weight: 700;

    @media only screen and (max-width: 415px) {
      font-size: 20px;
    }
    @media (min-width: 416px) and (max-width: 584px) {
      font-size: 20px;
    }
  }

  &__description {
    font-size: 16px;
    color: #999;
    margin-top: 20px;
    max-width: 500px;
  }

  &__close {
    color: #fff;
    width: 40px;
    height: 40px;
    background: transparent;
    outline: none;
    border: none;
    position: absolute;
    top: 30px;
    right: 30px;
  }
  &__blink_badge1 {
    cursor: pointer;
    background: rgb(248, 95, 6);
    color: #fff;
    padding: 5px 10px 5px 10px;
    border-radius: 18px;
    animation: blinker 1s linear infinite;
    margin-right: 10px;
    font-size: 12px;
  }
  &__blink_badge {
    cursor: pointer;
    background: rgb(245, 23, 3);
    color: #fff;
    padding: 5px 10px 5px 10px;
    border-radius: 18px;
    animation: blinker 1s linear infinite;
    margin-right: 10px;
    font-size: 12px;
  }
  &__blink_badge2 {
    cursor: pointer;
    background: rgb(248, 212, 6);
    color: #fff;
    padding: 5px 10px 5px 10px;
    border-radius: 18px;
    animation: blinker 1s linear infinite;
    margin-right: 10px;
    font-size: 12px;
  }
  &__expandMute{
    float: right !important;
    width: 35px !important;
    margin-right: -37px !important;
    margin-top: 0px !important;

    // @media only screen and (max-width: 415px) {
    //   float: right !important;
    //   width: 35px !important;
    //   margin-right: -15px !important;
    //   margin-top: -550px !important;
    // }
  }

  @media only screen and (max-width: 415px) {
    height: 213vw;
  }
  @media (min-width: 416px) and (max-width: 584px) {
    height: 180vw;
  }
  @media (min-width: 585px) and (max-width: 768px) {
    height:  150vw;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    height:  55vw;
  }
  
}
.list-details-preview {

@media only screen and (max-width: 415px) {
  bottom: 180px !important;
}
}

.trailorHeight {

@media only screen and (max-width: 415px) {
  margin-top: 205px;
}
@media (min-width: 416px) and (max-width: 584px) {
  margin-top: 260px;
}
@media (min-width: 585px) and (max-width: 768px) {
  margin-top: 420px;
}

}
.tabAdj {

  @media only screen and (max-width: 415px) {
    bottom: 0px !important;
  }
}
.tabHeightAdj {
  @media only screen and (max-width: 415px) {
    height:  160vw !important;
  }
  @media (min-width: 416px) and (max-width: 584px) {
    height:  130vw !important;
  }
  @media (min-width: 585px) and (max-width: 768px) {
    height:  85vw !important;
  }
}




/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
.trilerPlayer {
  @media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    width: 86vw;
}
}

.content{
  @media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    height: 240vw;//godd
}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    height: 300vw;//good
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    height: 222vw;//good

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    height: 223vw;
}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    height: 300vw;
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    height: 264vw;
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    height: 225vw;
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 
    height: 230vw;//good
}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 
    height: 227vw;
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    height: 229vw;
}

/* Portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
    height: 280vw;
}

/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 
    height: 231vw;
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    height: 232vw;

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
    height: 230vw;//must

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 
    height: 235vw;

}


@media only screen 
and (device-width: 360px) 
and (device-height: 640px) 
and (-webkit-device-pixel-ratio: 3){
  height: 240vw;
}

}


