.item {
  flex: 0 0 14%;
  transition: transform 300ms ease 100ms;
  margin: 0 2px;
  position: relative;

  img {
    height: 290px;
    width: 200px;
    vertical-align: top;

    @media (min-width: 1500px) and (max-width: 2000px){
      height: 310px;
      width: 235px;
    }
    @media (min-width: 2000px) and (max-width: 3000px){
      height: 450px;
      width: 340px;
    }
    @media (min-width: 3000px) and (max-width: 4000px){
      height: 600px;
      width: 520px;
    }
  }
 
}
