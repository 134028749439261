body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.btn-play {
  background-color: #e50914;
  border-color: #ff0a16;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  margin-right: 0.75em;
  padding: 0.57em 26px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

  color: #fff !important;
}
.play-btn {
  max-width: 18px !important;
  margin-right: 4px;
  position: relative;
  top: -1px;
  margin-top: 0 !important;
}

.like-dislike-btn {
  max-width: 35px !important;
  margin-top: 0 !important;
}
.video-js {
  display: block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  position: relative;
  padding: 0;
  font-size: 10px;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  font-family: Arial,Helvetica,sans-serif;
  word-break: initial;
}

.mainPlayer {
  width: 99vw;
  height: calc((100vw - 20vw) * 0.5625);
}

.scrolled{
  position: fixed;
  top: 0;
  left: 0;
  /* background-color: #000; */
}
h5.subHeading {
  color: rgb(0, 0, 0);
  font-weight: 700;
}
ul.listposition,
ol.listposition {
  list-style-position: outside;
  margin-left: 3rem;
}