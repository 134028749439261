/* 20 sept 2020 */



.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .5;
}

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.modal {
    z-index: 100;
    background: white;
    position: relative;
    margin: 1.75rem auto;
    border-radius: 3px;
    max-width: 500px;
    padding: 2rem;
}

.modal-header {
    display: flex;
    justify-content: flex-end;
    padding-top: 52px;
    padding-right: 35px;
}

.modal-close-button {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    opacity: 7.3;
    cursor: pointer;
    border: none;
}

button {
    font-size: .9rem;
    font-weight: 700;
    border: none;
    border-radius: 3px;
    padding: .3rem 1rem;
    margin-left: .5rem;
}

.button-default {
    background: #247BA0;
    color: #fff;
}

.d-none {
    display: none
}

._3nmo_success {
    color: #0b881e;
    text-align: center
}

._3nmo_success ._3nmo_success {
    -webkit-animation-name: GrX0c;
    animation-name: GrX0c;
    -webkit-animation-duration: .6s;
    animation-duration: .6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

._3nmo_success:empty {
    display: none
}


/* sept 23 */

.username {
    margin-left: .3rem;
    position: relative;
    padding-right: 10px;
    margin-right: 12px;
    margin-right: 0;
    flex-basis: 33%;
    font-size: 1rem;
    font-weight: 600;
}

@media (min-width: 768px) {
    .username:before {
        left: 170px
    }
}

.signout {
    color: #26262d;
    padding: .1rem 0;
    font-weight: 400;
    display: inline-block;
    position: relative;
    transition: opacity .3s;
    color: #e50914;
    line-height: 1.5;
    cursor: pointer;
}

.sign {
    position: absolute;
    padding: .8rem 1.5rem;
    border-radius: 2px;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    font-size: 15px;
    display: flex;
    width: 16.67rem;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
    border: 1px solid rgba(32, 32, 38, .1);
}

@media (max-width: 768px) {
    .sign {
        width: 100%;
        position: absolute;
        display: flex;
    }
    .signpadding {
        padding-left: 104px;
    }
    .signin {
        width: 20px;
    }
    .watchBottom {
        margin-left: 10px !important;
    }
}

._1xPbF {
    margin: 5px 0;
    border-bottom: 1px solid rgba(28, 31, 41, .88);
    opacity: .1;
}

.signpadding {
    padding-left: 1040px;
}

text-head {
    text-align: center;
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    margin-top: 90px;
}


/* 24th sept */

.subscribe-btn {
    background: none;
    color: #e50914;
    border: 1px solid #e50914;
    width: 170px;
    height: 50px;
    outline: none;
    margin-top: 35px;
    font-size: 17px;
    border-radius: 25px;
    transition-duration: 1s;
    margin-left: 25px;
}

.subscribe-btn:hover {
    background: #e50914!important;
    color: #fff!important;
}

.mytitle {
    padding: 12px;
    width: 250px;
    box-shadow: 0 10px 24px 0 rgba(238, 241, 250, .2);
    margin-bottom: 10px;
    border: 1px solid hsla(0, 0%, 100%, .5);
    margin-right: 10px;
}

.subscribe-box {
    border: 1px solid hsla(0, 0%, 100%, .1);
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 10px 24px 0 rgba(82, 91, 115, .12)
}

.player-back {
    position: absolute;
    top: 60px;
    left: 1.2%;
    z-index: 9!important;
    font-size: 16px;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.ic-arrow-left-bg {
    fill: var(--beacon-color-1);
    background-color: var(--beacon-color-1);
    opacity: .9;
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    justify-items: center;
    align-items: center;
    padding-left: 2px;
    box-sizing: border-box;
}

.playerContainer {
    width: 100%;
    height: 100%;
}

.liverOverlay {
    background-color: black;
    top: 20px;
    z-index: 9;
    margin: 0;
    width: 15%;
    height: 72%;
    position: absolute;
    ;
    opacity: 0.3;
    display: none;
}

.liveContainer {
    width: 100%;
    height: 100%;
}

.videoContainer:hover .liverOverlay {
    display: block;
}

.liverOverlay:hover {
    opacity: 0.8;
}


/* .mySlider{
      width: 100%;
      height: 100%;
  } */

.mySliderItem {
    left: 10px;
    right: 10px;
    width: 100%;
    height: 100px;
    position: relative;
    /* background-color: red; */
    opacity: 2.3;
}

.slick-slide {
    height: 100%;
}

.slick-list {
    height: 456px !important;
}


/* sign in register page design */

.d-none {
    display: none
}

._3nmo_success {
    color: #0B881E;
    text-align: center
}

._3nmo_success ._3nmo_success {
    -webkit-animation-name: GrX0c;
    animation-name: GrX0c;
    -webkit-animation-duration: .6s;
    animation-duration: .6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

._3nmo_success:empty {
    display: none
}

.eyeicon {
    position: absolute;
    top: 18px;
    -webkit-transition: top .2s, font-size .2s;
    -o-transition: top .2s, font-size .2s;
    transition: top .2s, font-size .2s;
    opacity: .5;
    line-height: 18px;
    pointer-events: none;
    right: 17px;
}

.text-head {
    text-align: center;
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    margin-top: 90px;
}

.mytitle {
    padding: 12px;
    width: 250px;
    box-shadow: 0 10px 24px 0 rgba(238, 241, 250, .2);
    margin-bottom: 10px;
    border: 1px solid hsla(0, 0%, 100%, .5);
    margin-right: 10px;
}

.subscribe-box {
    border: 1px solid hsla(0, 0%, 100%, .1);
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 10px 24px 0 rgba(82, 91, 115, .12)
}

.payment-box {
    color: #fff;
    font-size: 20px;
}

.cards1 {
    margin-left: 15px;
    width: 90px!important;
    border-radius: 8px;
    margin-right: 15px;
    cursor: pointer;
}

.cards {
    width: 80px!important;
    border-radius: 8px;
    cursor: pointer;
}

.myFieldSet {
    width: 310px !important;
    box-shadow: 0 10px 24px 0 rgba(82, 91, 115, .12)!important;
    padding-bottom: 20px!important;
    border: -1px!important
}

.card {
    margin-top: 100px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 95%;
    margin: auto;
    background-color: #000 !important;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.mycontainer {
    padding: 2px 16px;
    font-family: 'Varela Round', sans-serif;
}

.modal-confirm {
    color: #636363;
    width: 325px;
    font-size: 14px;
}

.modal-confirm .modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
}

.modal-confirm .modal-header {
    border-bottom: none;
    position: relative;
}

.modal-confirm h4 {
    text-align: center;
    font-size: 26px;
    margin: 30px 0 -15px;
}

.modal-confirm .form-control,
.modal-confirm .btn {
    min-height: 40px;
    border-radius: 3px;
}

.modal-confirm .close {
    position: absolute;
    top: -5px;
    right: -5px;
}

.modal-confirm .modal-footer {
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
}

.modal-confirm .icon-box {
    color: #fff;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -70px;
    width: 95px;
    height: 95px;
    border-radius: 50%;
    z-index: 9;
    background: #82CE34;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.modal-confirm .icon-box i {
    font-size: 58px;
    position: relative;
    top: 3px;
}

.modal-confirm.modal-dialog {
    margin-top: 80px;
}

.modal-confirm .btn {
    color: #fff;
    border-radius: 4px;
    background: #82CE34;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border: none;
}

.modal-confirm .btn:hover,
.modal-confirm .btn:focus {
    background: #6FB32B;
    outline: none;
}

.trigger-btn {
    display: inline-block;
    margin: 100px auto;
}


/* sept 28 styling */

.slick-list {
    height: auto !important;
}

.watchBottom {
    margin-left: 480px;
}

@media (min-height: 400px) {
    .topContainer {
        min-height: 0vh;
    }
}

@media (min-height: 700px) {
    .topContainer {
        min-height: 0vh;
    }
}

@media (min-height: 1440px) {
    .topContainer {
        min-height: 0vh;
    }
}

.videoPlayerContainer {
    width: 100%;
    height: 100%;
}

.playerOverlay {
    background-color: transparent;
    top: 0;
    left: 0;
    z-index: 9;
    margin: 0;
    margin-top: 84px;
    width: 100%;
    height: 100%;
    position: absolute;
    ;
    opacity: 0.3;
    display: none;
}

.videoContainer {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.videoContainer:hover .playerOverlay {
    display: block;
}

.playerOverlay:hover {
    opacity: 0.8;
}

.videoPlayIcon {
    width: 4rem;
    height: 4rem;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    color: #fff;
    transition: color .2s;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.videoPlayIcon circle {
    transition: r .2s ease-in-out
}

.videoPlayIcon:hover {
    color: #e50914
}

.kids-mode .videoPlayIcon:hover {
    color: #fea534
}

.videoPlayIcon:hover circle {
    r: 28px
}

@media (min-width: 768px) {
    .videoPlayIcon {
        width: 5rem;
        height: 5rem
    }
}

.eyeIcon {
    position: absolute;
    top: 38%;
    right: 3%;
}

.eyeIcon:hover {
    color: rgb(20, 138, 183);
    cursor: pointer;
}

.playerBackButton {
    top: 4%;
    border-radius: 10%;
    border: 1px solid #fff;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    left: 89%;
    z-index: 9999999999999999;
    padding-left: 10px;
    padding-right: 10px;
}

.Select__listItem {
    width: 100%;
    height: 54px;
    padding: 18px 16px 0;
    border-bottom: 1px solid rgba(38, 38, 45, .1);
    color: rgba(38, 38, 45, .5);
    list-style: none;
    cursor: pointer;
}

@media (max-width: 375px) {
    .mycontainer {
        padding: 0px;
        font-family: 'Varela Round', sans-serif;
    }
    .modal-dialog {
        margin: 0px !important
    }
}

@media (max-width: 540px) {
    .modal-confirm.modal-dialog {
        margin-top: 80px;
        margin-left: 0px !important;
    }
    .mycontainer {
        padding: 0px !important;
        font-family: Varela Round,sans-serif;
        }
    .packageMsg {
        margin-left: 0px !important;
    }
}
.packageMsg {
    margin-left: -58px;
}