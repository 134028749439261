.mark {
  box-sizing: border-box;
  border: solid 4px #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0px !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  z-index: 4;

  &:before,
  &:after {
    position: absolute;
    width: 0;
    height: 0;
    content: '';
    top: 100%;
    left: 50%;
    margin-left: -13px;
    border-style: solid;
    border-width: 7px 13px 0 13px;
  }

  &:before {
    border-color: rgba(0, 0, 0, 0.15) transparent transparent transparent;
    margin-top: 5px;
  }

  &:after {
    margin-top: 4px;
    border-color: #fff transparent transparent transparent;
  }
  @media (min-width: 1400) and (max-width: 2000px){
    padding: 10px !important;
  }

}

.markImg {
  height: 280px !important;
  width: 193px !important;

  @media (min-width: 1500px) and (max-width: 2000px){
    height: 310px !important;
    width: 235px !important;
  }

  @media (min-width: 2000px) and (max-width: 3000px){
    height: 450px !important;
    width: 340px !important;
  }
  @media (min-width: 3000px) and (max-width: 4000px){
    height: 600px !important;
    width: 520px !important;
  }
}