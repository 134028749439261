.item {
  flex: 0 0 14%;
  transition: transform 300ms ease 100ms;
  margin: 0 2px;
  position: relative;

  img {
    height: 290px;
    width: 200px;
    vertical-align: top;
  }
  &__blink_badge1 {
    cursor: pointer;
    background: rgb(248, 95, 6);
    color: #fff;
    padding: 5px 10px 5px 10px;
    border-radius: 18px;
    animation: blinker 1s linear infinite;
    margin-right: 10px;
    font-size: 12px;
  }
  &__blink_badge {
    cursor: pointer;
    background: rgb(245, 23, 3);
    color: #fff;
    padding: 5px 10px 5px 10px;
    border-radius: 18px;
    animation: blinker 1s linear infinite;
    margin-right: 10px;
    font-size: 12px;
  }
  &__blink_badge2 {
    cursor: pointer;
    background: rgb(248, 212, 6);
    color: #fff;
    padding: 5px 10px 5px 10px;
    border-radius: 18px;
    animation: blinker 1s linear infinite;
    margin-right: 10px;
    font-size: 12px;
  }
}
