.title {
    font-size: 17px;
    color: #fff;
    font-weight: bold;
    transition: color 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
    margin: 1vw 0;
    display: flex;
    align-items: center;
}

.search-title {
    font-size: 18px;
    color: #e50914;
    z-index: 2;
    font-weight: bold;
    margin: 0;
    position: absolute;
    text-decoration: none;
    display: inline-flex;
    min-width: 6em;
    left: 57px;
}