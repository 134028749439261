

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
.slider-frame .move-thumb {
    width: 72px;
    cursor: pointer;
    height: 60%;
    background: rgba(3, 3, 3, 0.3);
    position: absolute;
    top: 46px;
    z-index: 99;
    border-radius: 0;
}

.set-opacity{ opacity: 0.7; }
.slider-frame {
  /* overflow: hidden; */
  position: relative;
}

.slider-frame i {
    color: #fff;
    opacity: 0.8;
    display: block;
    margin: 0 auto;
    text-align: center;
    padding: 100% 0;
    height: 100%;
    line-height: 6px;
    font-size: 45px;
}

.inner header{ display: none !important; }

.slider-frame .next {
  right: 0px;
}

.slider-frame .slider-container {
  transition: margin-left .2s ease, left .5s ease;
  position: absolute;
  left: 0;
}

.slider-frame .slider-container .slide {
  background-color: #000;
  float: left;
  position: relative;
  top: 0;
  transition: width .2s ease, height .2s ease, top .2s ease;
}

.slider-frame .slider-container .slide:nth-child(even) {
  background-color: #fff;
}

hr {
  width: 100px;
}

.prev .fa {
  margin-top: 124px;
  padding-left: 7px;
}

.next .fa {
  margin-top: 124px;
  padding-left: 12px;
}

.btn {
  cursor: pointer;
}

body {
  background-color: rgb(0, 0, 0);
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
}

.logo {
  color: #C40E17;
  font-weight: 600;
  font-size: 18px;
  /* margin: 4px 14px; */
  line-height: 22px;
  text-transform: uppercase;
}

.position-relative .video-box-wxpand-wrpr-mask {
  position: fixed;
  left: 50%;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 6%, rgba(0, 0, 0, 0) 100%);
  z-index: 11;
}

.video-single {
  position: fixed !important;
  top: 0;
  height: 100%;
}

.fixed-top {
  z-index: 99999999999;
}

.close-video {
 background: rgba(0, 0, 0, 0.2);
    padding: 6px;
    padding-right: 6px;
    right: 10px !important;
    top: 81px !important;
    opacity: 0.5;
    max-width: 44px !important;
}

.close-video:hover {
  opacity: 1;
  background: #e50914;
  transition: opacity 0.5s;
}

.newHeight {
  height:143px !important;
}

header {
  z-index: 99999999;
  padding: 10px 0;
  position: relative;
  background: rgba(0, 0, 0, 1);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, 0)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0);
}

.logo-episode {
  max-width: 200px;
  width: 100%;
  margin-bottom: 20px;
}

.thumbnail-episode {
  /* height: 180px; */
  position: relative;
}

.episode-play-thumb {
  background: url('../img/play-thumb.png') center no-repeat;
  background-size: auto;
  border: 0;
  cursor: pointer;
  background-size: 100%;
  height: 70px;
  width: 70px;
  padding: 0;
  margin: 0 0 2px 0;
  display: block;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
}

.episode-heading {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0 3px 0;
}

.trailer-episode-heading {
  margin-bottom: 100px !important;
  opacity: 0.4;
}

.episode-number {
  display: block;
  position: absolute;
  bottom: 13px;
  color: #fff;
  font-size: 18px;
  left: 13px;
  font-weight: 600;
}

.episode-number-hover {
  display: block;
  position: absolute;
  bottom: 30px;
  color: #fff;
  font-size: 18px;
  left: 13px;
  font-weight: 600;
}

.owl-next {
  right: 0;
  position: absolute;
  top: 0;
  bottom: 6px;
  outline: 0 !important;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+28,000000+48,000000+74,000000+100&0+1,0.61+28,1+48,0.61+74,0+100 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.61) 28%, rgba(0, 0, 0, 1) 48%, rgba(0, 0, 0, 0.61) 74%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.61) 28%, rgba(0, 0, 0, 1) 48%, rgba(0, 0, 0, 0.61) 74%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.61) 28%, rgba(0, 0, 0, 1) 48%, rgba(0, 0, 0, 0.61) 74%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000', GradientType=0);
  /* IE6-9 */
  width: 82px;
  font-size: 58px !important;
  color: #fff !important;
}

.owl-prev {
  left: 0;
  outline: 0 !important;
  position: absolute;
  top: 0;
  bottom: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+28,000000+48,000000+74,000000+100&0+1,0.61+28,1+48,0.61+74,0+100 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.61) 28%, rgba(0, 0, 0, 1) 48%, rgba(0, 0, 0, 0.61) 74%, rgba(0, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.61) 28%, rgba(0, 0, 0, 1) 48%, rgba(0, 0, 0, 0.61) 74%, rgba(0, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.61) 28%, rgba(0, 0, 0, 1) 48%, rgba(0, 0, 0, 0.61) 74%, rgba(0, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000', GradientType=0);
  /* IE6-9 */
  width: 82px;
  font-size: 58px !important;
  color: #fff !important;
}

.owl-next.disabled,
.owl-prev.disabled {
  display: none;
}

.owl-nav {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.87);
  background: rgba(0, 0, 0, 0.87);
  z-index: 1
}

.thumbnail-mask {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

section {
  position: relative;
}

.episode-heading {
  margin: 0;
  position: relative;
  display: inline-block;
  margin: 7px 0 0 0;
  width: 100%;
  vertical-align: middle;
  color: #fff;
}

.title-info {
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 2px;
}

.episodeSynopsis {
  color: #999;
  margin-bottom: 71px;
  line-height: 17px;
  font-size: 13px;
}

.episode-duration {
  color: #fff;
  font-weight: 600;
  position: absolute;
  display: inline-block;
  right: 0;
  top: 0;
  font-size: 14px;
}

section.cat {}

.all-cats {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+17,000000+75&0+0,0.59+32,1+73 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.31) 17%, rgba(0, 0, 0, 0.59) 32%, rgba(0, 0, 0, 1) 73%, rgba(0, 0, 0, 1) 75%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.31) 17%, rgba(0, 0, 0, 0.59) 32%, rgba(0, 0, 0, 1) 73%, rgba(0, 0, 0, 1) 75%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.31) 17%, rgba(0, 0, 0, 0.59) 32%, rgba(0, 0, 0, 1) 73%, rgba(0, 0, 0, 1) 75%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
}

main {
  position: relative;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#141414+0,141414+100&0+0,1+100 */
}

.plus-btn {
  max-width: 13px !important;
  margin-right: 4px;
  position: relative;
  top: -1px;
  margin-top: 0 !important;
}

.btn-mylist {
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  margin-right: 0.75em;
  padding: 0.57em 1.35em;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  background-image: none;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff !important;
}

.btn-mylist:hover {
  background-color: rgba(51, 51, 51, 0.4);
  -webkit-transition: background-color 0.5s;
  /* Safari prior 6.1 */
  transition: background-color 0.5s;
}

.disable-item {
  pointer-events: none !important;
  position: relative;
  z-index: 99999999 !important;
  top: -8px;
}

.preview-expand .thumb-preview-shot-detailes-actions {
  right: 0;
  position: relative;
  margin: 10px 0 20px 0;
  margin-left: 20px;
}

.preview-expand .match .age {
  line-height: 16px;
  font-size: 13px;
}

.preview-expand .match {
  font-weight: 600;
  color: #2dbd58;
  text-shadow: 0px 0px 2px #000000;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 5px;
}

.preview-expand .thumb-preview-shot-detailes-actions li {
  width: auto !important;
}

.preview-expand .thumb-preview-shot-detailes-actions li img {}

.list-preview-video {
  z-index: -1;
  opacity: 0;
  left: -1px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: auto;
  width: 100%;
  height: auto;
  background: #000;
  left: 0;
  transform: scale(1.3);
}

.preview-player-wrpr {
  background: transparent;
}

nav {
  padding: 0 !important;
}

nav a {
  padding: 5px 12px !important;
  color: #e5e5e5;
  font-size: 14px;
}

nav .active a {
  color: #fff;
  font-weight: bold;
  cursor: default;
}

.logged-user:hover>.dropdown-menu {
  display: block;
}

nav a:hover,
nav a:focus {
  color: #b3b3b3;
  outline: 0;
}

.logged-user {
  width: 28px;
  cursor: pointer;
  opacity: 0.8;
  position: relative;
  top: 4px;
}


/*section{ background: #2b2b2b; }*/

.logged-user:hover {
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  /* Safari prior 6.1 */
  transition: opacity 0.5s;
}

.icon {
  position: relative;
  display: block !important;
  top: 3px;
  opacity: 0.8;
  cursor: pointer;
}

.icon:hover {
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  /* Safari prior 6.1 */
  transition: opacity 0.5s;
}

.thumb-preview-shot-detailes-actions {
  position: absolute;
  right: 16px;
  bottom: 7px;
}

.thumb-preview-shot-detailes-actions li {
  width: 26px;
  height: 26px;
}

.action-1 li {
  margin-bottom: 13px !important;
}

.thumb-preview-shot-detailes-actions li img {
  cursor: pointer;
  margin: 0 auto;
  display: block;
  opacity: 0.6;
  width: 26px;
}

.preview-expand .thumb-preview-shot-detailes-actions li img {
  cursor: pointer;
  margin: 0 auto;
  display: block;
  opacity: 0.6;
  width: 35px !important;
}

.watch-progress {
  width: 100%;
  background: #737373;
  border-radius: 3px;
  height: 2px;
  max-width: 70%;
  position: relative;
  margin: 18px 0 28px 0px;
}

.watch-progress .watched {
  position: relative;
  background: #C40E17;
  height: 2px;
  border-radius: 3px;
}

.watch-progress .time {
  position: absolute;
  right: -68px;
  color: #737373;
  font-weight: 600;
  display: inline-block;
  bottom: -8px;
  font-size: 13px;
}

.thumb-preview-shot-detailes-actions li img.active {
  -webkit-transition: width 0.2s;
  /* Safari prior 6.1 */
  transition: width 0.2s;
  opacity: 1;
  width: 30px !important;
}

.thumb-preview-shot-detailes-actions li img:hover {
  opacity: 1;
}

.search-icon {
  line-height: 1;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.search-box {
  animation: widthSet 1s !important;
  border: solid thin #b3b3b3 !important;
  bottom: 6px !important;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
  background: rgba(0, 0, 0, 0.5) !important;
  position: relative !important;
  color: #e5e5e5 !important;
  font-size: 12px !important;
  padding: 4px 10px !important;
  right: 0 !important;
  font-family: inherit !important;
  width: auto !important;
  z-index: 0 !important;
}

.close-search {
  position: absolute;
  display: block;
  top: 1px;
  right: 8px;
  color: #e5e5e5;
  animation: opacitySet 1s;
  font-size: 14px !important;
  opacity: 0.5;
  cursor: pointer;
}

.close-search:hover {
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  /* Safari prior 6.1 */
  transition: opacity 0.5s;
}

.d-none {
  display: none !important;
}

header .dropdown-menu {
  padding: 0;
  color: #e5e5e5;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0;
  color: #e5e5e5;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #b3b3b3 !important;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.9) !important;
  background: rgba(0, 0, 0, 0.9) !important;
}

header .dropdown-menu a {
  padding: 15px 26px;
  color: #e5e5e5;
}

@keyframes widthSet {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes opacitySet {
  from {
    opacity: 0%;
  }
  to {
    opacity: 50%;
  }
}

.banner-preview-info img {
  display: block;
  max-width: 247px;
  width: 100%;
  margin-top: 45px;
}

.banner-preview-info {
  margin: 65px 0;
  padding-bottom: 40px;
  /* padding-left: 41px; */
}

.banner-preview-info .title {
  font-size: 17px;
  color: #fff;
  font-weight: bold;
  -webkit-transition: color 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: color 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: color 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: color 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
  margin: 1vw 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.banner-preview-info .desc {
  color: #fff;
  font-weight: normal;
  line-height: 25px;
  width: 100%;
  font-size: 14px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
  margin-top: .1vw;
  max-width: 405px;
  width: 100%;
}

.banner-preview-info .cu-btn {
  background-color: rgba(51, 51, 51, 0.4);
  margin-top: 10px;
  margin-right: 10px;
  border-width: 0;
  padding: 14px 32px;
  -webkit-border-radius: 0.2vw;
  -moz-border-radius: 0.2vw;
  border-radius: 0.2vw;
  cursor: pointer;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 0.75em;
  display: revert;
  line-height: 8px;
}

.banner-preview-info .cu-btn:hover,
.banner-preview-info .cu-btn:focus {
  -webkit-box-shadow: 0 0.6vw 1vw -0.4vw rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 0.6vw 1vw -0.4vw rgba(0, 0, 0, 0.35);
  box-shadow: 0 0.6vw 1vw -0.4vw rgba(0, 0, 0, 0.35);
  background-color: #e6e6e6;
  color: #000;
}

.banner-preview-info .cu-btn i {
  position: relative;
  top: 12px;
  font-size: 32px;
}

.banner-preview-info .cu-btn i.info {
  position: relative;
  top: 7px;
  font-size: 23px;
  margin-right: 7px;
}


/*.thumb-large {
  min-width: 410px;

  position: absolute;
top: -50px;
  right: 0;
  left: 0;
  margin: 0 3px !important;
  transform: translate(300px);
  border: solid thin #181716 !important;
   transition:  min-width 0.5s;

}*/

.zoom-animation {
  transition: transform .2s;
  -ms-transform: scale(5.5);
  /* IE 9 */
  -webkit-transform: scale(5.5);
  /* Safari 3-8 */
  transform: scale(5.5);
}

.thumb-large.normal {
  width: auto;
  -webkit-transition: width 0.5s;
  /* Safari prior 6.1 */
  transition: width 0.5s;
}

.thumb-slider img {
  width: 100%;
  display: block;
}

.owl-carousel .owl-stage-outer.pad {
  /* padding: 30px 0 100px 0 !important;*/
  overflow: visible !important;
}

.fill-gap {
  animation: mymove 1s;
  animation-fill-mode: both;
}

.fill-gap-in {
  animation: mymoves 1s;
  animation-fill-mode: both;
}

footer {
  padding: 20px 0;
  position: relative;
  background-color: rgb(20, 20, 20);
  color: #888;
  font-size: 11px;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

footer p {
  margin-bottom: 0;
}


/*.container-fluid {
  width: 100%;
  padding-right: 60px;
  padding-left: 60px;
}*/

.section-title {
  font-size: 18px;
  color: #e5e5e5;
  z-index: 2;
  font-weight: bold;
  margin: 0;
  position: absolute;
  text-decoration: none;
  display: inline-flex;
  min-width: 6em;
  left: 57px;
}

.section-title .more {
  display: none;
  font-size: 12px;
  margin-right: 4px;
  line-height: 0.8vw;
  cursor: pointer;
  position: relative;
  top: -2px;
  opacity: 0;
}

.section-title .more i {
  position: relative;
  font-size: 17px;
  display: inline-block;
  left: -1px;
  top: 5px;
  height: auto;
  padding: 0px;
}

.section-title:hover .more {
  display: contents;
  opacity: 1
}

.owl-item {
  cursor: pointer;
  position: relative;
}

.thumb-holder {
  min-height: 130px;
  overflow: hidden;
  background-size: 100% !important;
}

.cover-image {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

.listLabel,
.inner-small-head {
  font-size: 15px;
  color: #808080;
  margin: 20px 0 4px 0px;
  display: block;
  font-weight: bold;
}

.maturityDescription {
  color: #808080;
  font-size: 14px;
}

.maturity-number {
  background: #e5e5e5;
  margin-bottom: 15px;
  padding: 2px 5px;
  color: #000;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  margin: 15px 0 5px 0;
}

.list-tab li a {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
}

.list-details-preview {
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  z-index: 999;
  align-items: stretch;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  z-index: 9999;
}

.thumbnail-list-wrpr {
  position: relative;
  z-index: 9999;
  right: 0;
  left: 0;
  padding-bottom: 20px;
}

.list-details-preview li {
  cursor: pointer;
  text-transform: uppercase;
  color: #fff !important;
  text-align: center;
  margin-right: 43px !important;
  font-weight: 600;
  font-size: 15px;
  padding: 20px 0 5px 0;
  display: block;
}

.list-details-preview li:hover {
  opacity: 1 !important;
  -webkit-transition: opacity 0.5s;
  /* Safari prior 6.1 */
  transition: opacity 0.5s;
}

.preview-expand {
background: #111;
    position: relative;
    overflow: hidden;
    right: 0;
    left: 0;
    top: -38px;
    z-index: 999999;
}

.thumbnail-episode .thumb-preview-shot-detailes-actions {
  position: absolute;
  bottom: 0;
  right: 15px;
}

.note-desc {
  color: #999;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 18px;
}

.match-score {
  font-weight: 600;
  margin-top: 11px;
  color: #46D369;
  display: inline-block;
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 5px;
}

/* #showDetails {
  padding: 60px 0 100px 0;
} */

.mask-preview-expand {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.95);
  background: rgba(0, 0, 0, 0.95);
  background-repeat: no-repeat;
  background-position: center center fixed !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

.list-details-preview li.active {
  border-bottom: solid 4px #C40E17;
}

.video-box-wxpand-wrpr {
  top: -116px;
}

.preview-expand .info {
  z-index: 1;
  position: relative;
  background: rgb(0, 0, 0);
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.6082632882254464) 94%, rgba(0, 0, 0, 0.5466386383655024) 100%);
  padding: 0 60px 0 30px;
  max-width: 50%;
}

.thumb-preview-shot-detailes {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  color: #fff;
  padding: 15px 20px 0 20px;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000', GradientType=0);
  top: 0;
}

.thumb-expand {
  display: block;
  cursor: pointer;
  max-width: 100%;
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  opacity: 0.7;
  margin-top: 0;
  margin-bottom: 0px;
  bottom: 2px;
  top: auto;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  background-size: 25px !important;
  background-repeat: no-repeat !important;
  background-position: center bottom !important;
}

.thumb-expand:hover {
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  /* Safari prior 6.1 */
  transition: opacity 0.5s;
}

.thumb-preview-shot-detailes .title {
  color: #fff;
  text-shadow: 0px 0px 2px #000000;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  margin: 6px 0 10px 0;
  max-width: 87%;
}

.synopsis {
  color: #999;
  font-size: 15px;
  line-height: 24px;
  margin: 13px 0px;
}

.play-red {
  font-size: 14px;
  font-weight: bold;
  margin-right: 0.75em;
  padding: 0.57em 1.35em;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #E50914;
  border-color: #FF0A16;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.play-red:hover {
  background-color: #FF0A16;
  color: #fff !important;
  -webkit-transition: background-color 0.5s;
  /* Safari prior 6.1 */
  transition: background-color 0.5s;
}

.play-red i {
  position: relative;
  top: 7px;
}

.match {
  font-weight: 600;
  color: #2dbd58;
  text-shadow: 0px 0px 2px #000000;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 12px;
}

.match .age {
  border: solid 1px #6c6c6c;
  text-shadow: 0px 0px 2px #000000;
  color: #fff;
  display: inline-block;
  line-height: 9px;
  padding: 7px 2px;
  font-size: 15px;
  margin: 0 4px;
  font-weight: 500;
}

.note {
  font-size: 13px
}

.play-thumb {
  background: url('../img/play-thumb.png') center no-repeat;
  background-size: auto;
  border: 0;
  display: block;
  cursor: pointer;
  background-size: 100%;
  height: 40px;
  width: 40px;
  padding: 0;
  margin: 0 0 5px 0;
  bottom: 3px;
  position: relative;
  margin-top: 20px;
}

.thumb-preview-shot-detailes .match .duration {
  color: #fff;
  text-shadow: 0px 0px 2px #000000;
}

.card {
  border-radius: 0 !important;
  border: 0 !important;
}

.owl-item.active .thumb-holder {
  /*min-height: 159px !important;*/
}

.thumb-holder.activated {
  min-height: 144px !important;
}

.large-cu {}

.thumb-holder.active-thumb {
  /*min-height: 300px !important;
position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
z-index: 999;*/
  height: 160px;
  background-size: 100% !important;
  z-index: 999;
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: transform 0.5s;
  -webkit-box-shadow: -7px -1px 5px 0px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: -7px -1px 5px 0px rgba(0, 0, 0, 0.42);
  box-shadow: -7px -1px 5px 0px rgba(0, 0, 0, 0.42);
}

.large-cu {
  transform: scale(1) !important;
  transition: transform 0.5s;
}

.close-panel {
  position: absolute;
  right: 25px;
  top: 26px;
  z-index: 9999;
  max-width: 36px;
  cursor: pointer;
}

.thumb-expand-expaned {
  position: absolute;
  opacity: 1;
  bottom: 20px;
  left: 0;
  right: 0;
  min-width: 91px !important;
  margin: 0 auto;
}

.re-hover {
  background: red;
}

.owl-dots {
  display: none;
}

.owl-stage-outer {
  padding-bottom: 0px;
}

.thumb-holder.border {
  position: relative;
}

.arrow-down {
  position: absolute;
  bottom: -30px;
  display: block;
  max-width: 27px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999999;
  top: auto;
  height: 30px;
  width: 30px;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
}

.preview-expand-active {
  border: solid 5px #fff !important;
}

.owl-item.cloned.active::after {
  content: '';
  opacity: 0;
  background: url('../img/arrow-down.png') center top;
  display: block;
  width: 100%;
  height: 15px;
  position: absolute;
  bottom: -14px;
  background-size: 30px;
  background-repeat: no-repeat;
}

.banner-video-player {
  opacity: 0;
}

#banner-preview-video {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.fixed-top {
  background: #111;
}

#expand-preview-video {
  position: fixed;
  right: 0;
  bottom: 0;
  max-width: 100%;
  min-height: 100%;
  top: 0;
  left: 50%;
}

@keyframes mymove {
  from {
    margin-right: 10px;
  }
  to {
    margin-right: 0px;
  }
}

@keyframes mymoves {
  from {
    margin-right: 0px;
  }
  to {
    margin-right: 10px;
  }
}

@keyframes leaves {
  0% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(2.0);
  }
}

.age-limit {
  border: solid 3px #6c6c6c;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-style: none none none solid;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 22px;
  padding: 17px 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2.4vw;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  right: 0;
  z-index: 9;
  bottom: 245px;
  font-weight: 600;
  color: #b0b0b0;
  font-size: 13px;
}

.thumb-preview-shot-detailes-actions-banner {
  z-index: 99;
  right: 105px;
  bottom: 117px;
}

.banner-video-actions {
  z-index: 99;
  right: 92px;
  bottom: 231px;
}

.banner-video-actions img {
  width: 26px !important;
}

.short-info .thumb-holder.active-thumb {
  transform: scale(1) !important;
}

.short-info .short-info.active .thumb-expand,
.short-info .thumb-preview-shot-detailes-actions,
.short-info .title,
.short-info .match,
.short-info .note,
.short-info .watch-progress,
.short-info .play-thumb,
.short-info .list-preview-video {
  display: none;
}

.short-info .thumb-expand {
  display: none !important;
  bottom: 20px;
}

.thumb-holder:hover .thumb-expand {
  display: block !important;
}

.border-new {
  border: solid 5px #fff !important;
}

.short-info .thumb-preview-shot-detailes {
  background: transparent !important;
}

a,
button {
  text-decoration: none;
  outline: none !important;
  text-decoration: none !important;
}

.tab-wrpr-switch {
  display: none;
}


/*===========================================MEDIA=========================*/

@media screen and (max-width:1199px) {
  .slider-frame .move-thumb {
    top: 34px;
  }
  .slider-frame i {
    line-height: 15px;
  }
}

@media screen and (max-width:999px) {
  #expand-preview-video {
    position: relative !important;
    left: 0;
  }
  .video-box-wxpand-wrpr {
    top: 0;
  }
  .banner-preview-info {
    padding-bottom: 70px;
  }
  .banner-preview-info img {
    margin-top: 0;
  }
  .position-relative .video-box-wxpand-wrpr-mask {
    left: 0;
    position: absolute;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%) !important;
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%) !important;
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%) !important;
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000', GradientType=0) !important;
    /* IE6-9 */
  }
  .preview-expand .info {
    max-width: 100%;
  }
  .slider-frame .move-thumb {
    top: 20px
  }
  .slider-frame i {
    line-height: 0;
  }
  .tab-wrpr {
    display: none;
  }
  .tab-wrpr-switch {
    display: block;
    position: absolute;
    z-index: 999;
    left: 20px;
    top: 20px;
    color: #fff;
    cursor: pointer;
    opacity: 0.8;
  }
  .tab-wrpr-switch:hover,
  .tab-wrpr-switch:focus {
    opacity: 1;
  }
  .tab-wrpr-switch i {
    font-size: 28px;
  }
  .close-panel {
    top: 13px;
    z-index: 999;
  }
  .list-details-preview li {
    font-size: 12px;
    margin-right: 20px;
    padding: 13px 15px;
    text-align: left;
    margin: 0 !important;
  }
  .list-details-preview li.active {
    border-bottom: 0;
    color: #C40E17 !important;
  }
  /* .list-details-preview {
    position: fixed;
    bottom: auto;
    top: 28px;
    background: rgba(0, 0, 0, 0.7);
    left: 58px;
    max-width: 192px;
    width: 100%;
    margin: 0;
  } */
  .panel-wrpr {
    background: #000;
  }
}

@media screen and (max-width:767px) {


  .thumb-holder.activated {
    min-height: 88px !important;
}

.newHeight {
    height: 86px !important;
}

  .close-video {
  
    right: 20px !important;
    /* top: 20px !important; */
}
  section.cat {
    margin: 10px 0 0 0;
  }
  .section-title .more i {
    top: 4px;
  }
 
  .preview-expand {
    top: -24px;
  }
  header {
    z-index: 99999;
  }
  section.cat {
    overflow: hidden;
    background: #000;
    position: relative;
    right: 0;
    left: 0;
    margin: 0;
    padding: 0;
  }
  .section-title {
    left: 0 !important;
    position: relative;
    display: block;
    /* margin: 30px 0; */
    text-align: center;
    /* top: 14px; */
  }


 
  .slider-frame i {
    line-height: 77px !important;
  }

  .close-search {
    top: 11px;
    right: 17px;
  }
  .list-header {
    position: fixed !important;
    top: 10px;
    right: 20px;
  }
  .logged-user {
    width: 20px;
    cursor: pointer;
    opacity: 0.8;
    position: relative;
    top: 1px;
  }
  .navbar-toggler {
    position: fixed;
    top: 9px;
    right: 1px;
  }
  .search-wrpr {
    /* position: fixed; */
    left: 0;
    right: 0;
    top: 6px;
    z-index: 99;
  }
  .search-box {
    background: #000;
    width: 100%;
    height: 48px;
    left: 0;
    border: 0;
  }
  header nav a {
    padding-left: 0 !important;
  }
}

@media screen and (max-width:575px) {

  .slider-frame .move-thumb {
  height: 71%;
    width: 30px;
}
  .watch-progress .time {
    right: -49px;
    bottom: -6px;
    font-size: 10px;
  }
  .list-buttons,
  .list-buttons li {
    width: 100%;
  }
  .preview-expand .info {
    padding: 0 20px !important;
  }
  .play-red,
  .btn-mylist {
    width: 100% !important;
    display: block;
    max-width: 200px;
    /* margin: 5px auto !important; */
  }
  .watch-progress {
    max-width: 80%;
  }
  .preview-expand .thumb-preview-shot-detailes-actions {
    right: 0;
    position: relative;
    margin: 20px 0 40px 0;
    left: 0;
    width: 100%;
    clear: both;
    top: 0;
  }

  .match {
    font-size: 10px;
    line-height: 17px;
    margin-bottom: 8px;
}

.thumb-preview-shot-detailes-actions li img {

    width: 15px;
}

.thumb-expand {
   background-size: 13px !important;
    bottom: 16px;
  
}
.slider .watch-progress {
    max-width: 44%;

    margin: 12px 0 28px 0px;
}
.note {
    font-size: 8px;
}
.match .age {
   

    padding: 3px 2px;
    font-size: 9px;
    margin: 0 4px;
}
  .list-preview-video {
    width: 500px;
  }
  .slide {
    background-size: cover !important;
  }
  .play-thumb {
    height: 15px;
    width: 15px; margin: 0  !important;
  }

  .thumb-preview-shot-detailes .title {
margin: 6px 0 0 0;
    font-size: 11px;
    line-height: 15px;
}
  a,
  button {
    text-decoration: none;
    outline: none !important;
    text-decoration: none !important;
  }
  .thumb-preview-shot-detailes-actions {
    bottom: auto;
    top: 15px;
    right: 13px;
  }
  .action-1 li {
    margin-bottom: 7px !important;
  }
  .thumb-preview-shot-detailes-actions li {
    width: 15px;
    height: 15px;
  }
 
  .list-header {
    right: 35px !important;
  }
  .banner-preview-info {
    padding-left: 0;
    /* text-align: center; */
  }
  .search-wrpr {
    top: 5px;
    left: 0;
    right: 0;
    z-index: 999999;
  }
  .banner-preview-info .desc {
    max-width: 90%;
    margin: 0 auto;
    margin-bottom: 0px;
    margin-bottom: 30px;
  }
  .banner-preview-info img {
    margin: 0 auto;
  }
  .banner-preview-info .title {
    text-align: center !important;
    display: block;
    margin-bottom: 20px;
  }
  .banner-preview-info .cu-btn {
    display: block;
    max-width: 80%;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  .banner-preview-info .cu-btn i,
  .banner-preview-info .cu-btn i.info {
    top: 0;
  }
  .icon-play {
    top: 3px !important;
  }
  .text-cu {
    position: relative;
    top: -9px;
  }
  .banner-preview-info {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .age-limit {
    top: 85px;
    padding: 17px 5px;
  }
  .banner-video-actions {
    right: 20px;
    bottom: auto;
    top: 135px;
  }
  .banner-preview-info img {
    max-width: 160px;
  }
  .slider-frame i {
line-height:44px !important;
font-size: 23px;
  }
 /* .section-title {
   
    top: 26px;
} */
}

.moreViewAdj{
  display: block;
  float: right;
  font-size: 14px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  margin-left: 94%;
  font-weight: bolder;
  position: absolute;
  z-index: 9999;
  margin-top: 20px;
}
@media screen and (max-width:584px) {
  .moreViewAdj{
    margin-left: 80%;
  }
}

.bannerImgAdj {
  height: 730px
}
@media (min-height: 700px) and (max-height:850px){
  .bannerImgAdj {
    height: 720px
  }
}
@media (min-height: 851px) and (max-height:1100px){
  .bannerImgAdj {
    height: 865px
  }
}
@media (min-height: 1101px) and (max-height:1500px){
  .bannerImgAdj {
    height: 1160px
  }
}
@media (min-height: 1501px) and (max-height:2200px){
  .bannerImgAdj {
    height: 1780px
  }
}
@media (max-width:600px){
  .KBrro .TRD_Q {
    width : 360px !important
  }
  .buttonBgFB {
    top: 12px !important;
  }
  .expandMuteAdj {
    height:  33px !important;
    margin-right: -28px !important;
    width: 30px !important
  }
  .list-details-preview li {
    padding: 13px 12px;
  }
}
.showDetailAdj {
  margin-top: -50px !important;
  margin-bottom: 10px;
}
.headerAdj {
  margin-top: 100px !important;
}
.signColor {
  color: #707c86
}

